<template>
	<v-data-table
		:loading="loading"
		:headers="headers"
		:items="i18nPhrasesList.items"
		:options.sync="options"
		:server-items-length="i18nPhrasesList.total"
		class="elevation-1"
		:footer-props="{
			itemsPerPageOptions: [5,  15, 25, 50, 100],
			itemsPerPageText: 'Строк на стр.'
		}"
		loading-text="Загрузка данных"
	>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Лейблы</v-toolbar-title>
				<v-divider vertical inset class="mx-6"/>
				<v-dialog
					v-model="dialogEdit"
					max-width="500px"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							color="primary"
							dark
							class="white--text"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon left dark>mdi-plus</v-icon>
							Добавить
						</v-btn>
					</template>
					<v-card>
						<v-card-title>{{ formTitle }}</v-card-title>
						<v-card-text>
							<v-form
								ref="form"
								v-model="valid"
								lazy-validation
							>
								<v-container>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-text-field
												v-model="editedItem.name"
												label="Имя"
												outlined
												hide-details="auto"
												:rules="[rules.required]"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col
											cols="12"
											sm="12"
											md="12"
										>
											<v-combobox
												v-model="editedItem.place"
												label="Место где используется"
												outlined
												:items="i18nPhrasesPlaces.items"
												item-text="name"
												:return-object="false"
												:rules="[rules.required]"
												hide-details="auto"
												:search-input.sync="search"
											>
												<template v-slot:no-data>
													<v-list-item>
														<v-list-item-content>
															<v-list-item-title>
																Совпадений не найдено. Нажмите <kbd>Enter</kbd>, чтобы добавить новое <br> местоположение подсказки
															</v-list-item-title>
														</v-list-item-content>
													</v-list-item>
												</template>
											</v-combobox>
										</v-col>
									</v-row>
								</v-container>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-spacer/>
							<v-btn
								color="grey darken-1"
								text
								@click="resetEditedItem"
							>
								Отменить
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="saveItem"
								:disabled="!valid"
							>
								{{ editedIndex === -1 ? 'Создать' : 'Сохранить' }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title>Точно удалить?</v-card-title>
						<v-card-actions>
							<v-spacer/>
							<v-btn color="grey darken-1" text @click="resetEditedItem">Отмена</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
							<v-spacer/>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-spacer/>
				<v-col cols="6">
					<v-autocomplete
						outlined
						hide-details
						clearable
						dense
						no-data-text="Нет данных"
						:items="i18nPhrasesPlaces.items"
						item-text="name"
						:menu-props="{ top: false, offsetY: true }"
						label="Фильтр по местоположению"
						v-model="filters.place"
						@change="updateFilters('place', $event)"
					/>
				</v-col>
			</v-toolbar>
			<v-divider/>
		</template>
		<template v-slot:item.actions="{ item }">
			<v-btn
				icon
				@click="editItem(item)"
				small
			>
				<v-icon small>mdi-pencil</v-icon>
			</v-btn>
			<v-btn
				icon
				@click="deleteItem(item)"
				small
			>
				<v-icon small>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<template v-slot:no-data>
			<v-btn
				color="primary"
				@click="resetLabelEntity"
			>
				Сбросить
			</v-btn>
		</template>
	</v-data-table>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	data: function () {
		return {
			title: 'Лейблы',
			options: {},
			dialogEdit: false,
			dialogDelete: false,
			loading: true,
			headers: [
				{
					text: 'ID',
					align: 'start',
					sortable: false,
					value: 'id'
				},
				{
					text: 'Имя',
					sortable: false,
					value: 'name'
				},
				{
					text: 'Место',
					sortable: false,
					value: 'place'
				},
				{
					text: 'Действия',
					value: 'actions',
					sortable: false
				}
			],
			editedIndex: -1,
			editedItem: {
				id: '',
				place: '',
				name: '',
				type: 'label'
			},

			defaultItem: {
				id: '',
				place: '',
				name: ''
			},

			filters: {
				place: '',
				limit: 20
			},

			rules: {
				required: value => !!value || 'Поле обязательно'
			},

			valid: true,
			search: null
		}
	},

	computed: {
		...mapGetters({
			i18nPhrasesList: 'i18n/i18nPhrasesList',
			i18nPhrasesPlaces: 'i18n/i18nPhrasesPlaces'
		}),

		formTitle () {
			return this.editedIndex === -1 ? 'Новый лейбл' : 'Редактировать лейбл'
		},

		/* Фильтры */
		urlFilters () {
			/* Инициализация фильтров */
			const urlFilters = this.$route.query
			const filters = {}

			filters.page = +urlFilters.page || 1
			filters.limit = +urlFilters.limit || 15

			if (urlFilters.place) {
				filters.place = urlFilters.place
			}

			return filters
		}
	},
	watch: {
		/* Отслеживание изменения фильтров */
		'$route' (to, from) {
			this.initialize()
		},

		/* Отслеживание изменения состояния таблицы */
		options (to) {
			const filters = {}
			if (this.urlFilters.page !== to.page) {
				filters.page = to.page
			}

			if (this.urlFilters.limit !== to.itemsPerPage) {
				filters.limit = to.itemsPerPage
			}

			if (filters.page || filters.limit) {
				this.updateUrl(filters)
			}
		},

		dialogEdit (val) {
			if (!val) {
				this.resetEditedItem()
			}
		},

		dialogDelete (val) {
			if (!val) {
				this.resetEditedItem()
			}
		}
	},

	created () {
		/* Инициализация фильтров */
		this.$set(this, 'filters', this.urlFilters)
		this.initialize()
		this.initPlaceFilter()
	},

	methods: {
		...mapActions({
			getPhrasesList: 'i18n/getPhrasesList',
			getPlaces: 'i18n/getPlaces',
			putPhrasesList: 'i18n/putPhrasesList',
			postPhrasesList: 'i18n/postPhrasesList',
			deletePhrasesList: 'i18n/deletePhrasesList'
		}),

		/* Обновление URL */
		async updateUrl (query) {
			await this.$router.push({
				query: {
					...this.$route.query,
					...query
				}
			})
		},

		updateFilters (filter, value) {
			const filters = {
				[filter]: value ? String(value) : undefined
			}

			const queryParams = this.$route.query

			switch (true) {
			case filter === 'place' && queryParams[filter] !== value && queryParams[filter] !== null && value !== null:
			case filter === 'limit':
				filters.page = undefined
				break
			}

			this.updateUrl(filters)
		},

		/* Инициализация данных */
		async initialize () {
			this.loading = true

			this.$set(this, 'options', {
				...this.options,
				itemsPerPage: this.urlFilters.limit,
				page: this.urlFilters.page
			})

			try {
				await this.changeLabelEntity()
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}

			this.loading = false
		},
		/* Загрузка списка местоположений */
		async initPlaceFilter () {
			this.loading = true

			try {
				await this.getPlaces({ type: 'label' })
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},

		/* Загрузка списка подсказок */
		async changeLabelEntity () {
			this.loading = true
			try {
				await this.getPhrasesList({
					type: 'label',
					place: this.urlFilters.place,
					limit: this.urlFilters.limit,
					offset: (this.urlFilters.page - 1) * this.urlFilters.limit
				})
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
			this.loading = false
		},

		/* Очистка данных диалоговых окон */
		resetEditedItem () {
			this.$nextTick(() => {
				this.$set(this, 'editedIndex', -1)
				this.$set(this, 'editedItem', this.defaultItem)
				this.$set(this, 'dialogDelete', false)
				this.$set(this, 'dialogEdit', false)
			})
		},

		deleteItemConfirm () {
			this.deleteTranslateEntityTypesList(this.editedItem).then(
				() => {
					this.resetEditedItem()
				},
				(error) => {
					this.$notify({
						type: 'error',
						title: error
					})
				}
			)
		},

		deleteItem (item) {
			this.$set(this, 'editedIndex', this.i18nPhrasesList.items.indexOf(item))
			this.$set(this, 'editedItem', { ...item })
			this.$set(this, 'dialogDelete', true)
		},

		editItem (item) {
			this.$set(this, 'editedIndex', this.i18nPhrasesList.items.indexOf(item))
			this.$set(this, 'editedItem', { ...item })
			this.$set(this, 'dialogEdit', true)
		},

		saveItem () {
			try {
				this[this.editedIndex > -1 ? 'putPhrasesList' : 'postPhrasesList'](
					this.editedItem
				).then(
					() => {
						this.$notify({ type: 'info', title: this.editedIndex > -1 ? 'Изменения сохранены' : 'Лейбл добавлен' })
						this.changeLabelEntity()
						this.initPlaceFilter()
						this.resetEditedItem()
					}, (error) => {
						this.$notify({
							type: 'error',
							title: error
						})
					}
				)
			} catch (error) {
				this.$notify({
					type: 'error',
					title: error
				})
			}
		},

		async resetLabelEntity () {
			await this.updateUrl({
				page: undefined,
				limit: undefined
			})

			await this.initialize()
		}
	}
}
</script>
